.whatsapp_float {
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
}

.whatsapp_float_btn {
  border-radius: 63%;
  height: 60px;
  width: 60px;
}

.show-menu {
  display: none;
}

.css-o69gx8-MuiCardMedia-root {
  width: none;
}

.head1 {
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
}

.head2 {
  color: white;
  font-weight: 100;
  font-size: 11px;
  line-height: 24px;
}

/* new css */

/* .main {
  text-align: center;
}

.marq {
  padding-top: 18px;
  padding-bottom: -20px;
}

.geek2 {
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin-top: 75px;
  margin-bottom: 6px;
} */


.tej{
  padding: 12px 30px;
}